html {
  overflow: hidden; /* Prevents the html element from scrolling */
}

body {
  overflow-y: scroll; /* Ensures the scrollbar is always visible */
  margin: 0; /* Avoid margin causing layout shifts */
  height: 100vh; /* Ensure body takes up full viewport height */
  scrollbar-gutter: stable; /* Reserves space for the scrollbar to prevent layout shift */
}
@font-face {
  font-family: "Aleo";
  src: url("./assets/fonts/Aleo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Aleo";
  src: url("./assets/fonts/Aleo-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Aleo";
  src: url("./assets/fonts/Aleo-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
.robot-icon {
  color: gray; /* Default color */
  transition: color 0.3s; /* Smooth transition for color change */
}

.robot-icon:hover {
  color: pink; /* Color changes to pink on hover */
}

.robot-icon:active {
  color: red; /* Color changes to red on click */
}

.nav-link {
  color: black;
  text-decoration: none;
  margin-right: 20px;
  font-weight: normal;
  transition: color 0.3s ease, text-decoration 0.3s ease, font-weight 0.3s ease;
}

.nav-link-active {
  color: rgb(236, 28, 36);
  text-decoration: underline;
  font-weight: bold;
  text-decoration: none;
}

.nav-link:hover {
  color: rgba(236, 28, 36);
}
/* .MuiCardContent-root {
  padding: 0 !important;
} */
